import React from 'react';

import Logo from '../../../../assets/logowhite.png';
import { Container } from './styles';

const Header: React.FC = () => (
  <Container>
    <img src={Logo} alt={Logo} />
    <a href="https://portal.spotsdelivery.com">
      <button type="button">Acessar meu painel</button>
    </a>
  </Container>
);

export default Header;
