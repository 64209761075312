import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 15%;
  height: 100px;
  width: 100%;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  img {
    width: 180px;
    color: #fff;
  }
  a {
    button {
      border: none;
      color: #f58a07;
      background: inherit;
      font-size: 16px;
      font-weight: 700;
      transition: border-bottom 1s;
    }
  }
  &:hover {
    a {
      button {
        border-bottom: 1px solid #f58a07;
      }
    }
  }
  @media screen and (max-width: 1150px) {
    padding: 0 5%;
  }
  @media screen and (max-width: 750px) {
    a {
      display: none;
    }
  }
`;
