import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

// type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isBlank?: boolean;
}

const Button: React.FC<ButtonProps> = ({ isBlank, children, ...rest }) => (
  <Container type="button" isBlank={!!isBlank} {...rest}>
    {children}
  </Container>
);

export default Button;
