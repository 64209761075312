import { createGlobalStyle } from 'styled-components';

// import gitback from '../assets/gitback.svg';

export default createGlobalStyle`

*{
  margin: 0;
  padding:0;
  outline: 0;
  box-sizing: border-box;
  }

  body{
    background: #fff ;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button, h1, h2, h3, h4, p{
    font: 10px Roboto, sans-serif;
  }

  #root {
    margin: 0;
  }

  button{

    cursor: pointer;
  }
`;
