import React from 'react';

import { Container } from './styles';

interface CardDashboardProps {
  title: string;
}

const CardDashboard: React.FC<CardDashboardProps> = ({ children, title }) => {
  return (
    <Container>
      <div>{children}</div>
      <p>{title}</p>
    </Container>
  );
};

export default CardDashboard;
