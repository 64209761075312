import React from 'react';
import ScreenOne from '../../../../assets/mobile01_01.png';
import ScreenTwo from '../../../../assets/mobile01_02.png';
import ScreenThree from '../../../../assets/mobile01_03.png';
import { Container } from './styles';

interface ContainerProps {
  timer: number;
}

const ExampleOne: React.FC<ContainerProps> = ({ timer }) => {
  return (
    <Container show={timer}>
      <img src={ScreenOne} alt="screenOne" />
      <img src={ScreenTwo} alt="screenTwo" />
      <img className="boranites" src={ScreenThree} alt="screenThree" />
    </Container>
  );
};

export default ExampleOne;
