import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding: 30px;

  @media screen and (min-width: 852px) {
    width: 33%;
  }
  h4 {
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    padding-bottom: 10px;
    color: #fff;
  }
  p {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #fff;
    opacity: 0.5;
  }
  svg {
    color: #fff;
    margin: 20px auto;
  }
`;
