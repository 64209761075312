import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0px;
  svg {
    color: rgba(29, 62, 139, 1);
    display: none;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #565656;
    margin-bottom: 0;
  }
  @media screen and (max-width: 850px) {
    justify-content: center;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 650px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    svg {
      display: block;
    }
  }
`;
