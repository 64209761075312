import styled, { keyframes } from 'styled-components';

export const Container = styled.div``;

const appearModal = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const ModalSendMessage = styled.div`
  width: 80%;
  max-width: 500px;
  position: fixed;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  background-color: #fff;
  z-index: 1000;
  border-radius: 5px;
  animation: ${appearModal} 0.5s;

  h4 {
    width: 100%;
    font-size: 18px;
    color: #535b7c;
    margin: 0 auto 10px auto;
    font-weight: 100;
    text-align: center;
    line-height: 1.7;
  }
  h3 {
    width: 100%;
    font-size: 14px;
    color: #535b7c;
    opacity: 0.7;
    margin: 0 auto 10px 0;
    font-weight: 100;
    text-align: center;
    line-height: 1.7;
  }
`;
