import styled, { css, keyframes } from 'styled-components';

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

interface ContainerProps {
  show: number;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50%;
  animation: ${appear} 2s;
  img {
    width: 50%;
    margin: 10px;
  }

  @media screen and (max-width: 700px) {
    .boranites {
      display: none;
    }
    img {
      width: 75%;
      margin: 10px;
    }
  }

  ${(props) =>
    props.show === 3
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}
`;
