import React, { useRef, useCallback, useState } from 'react';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useToast } from '../../../../hooks/toast';
import getValidationErrors from '../../../../utils/getValidationErrors';

import Button from '../../../../components/Button';
import Input from '../../../../components/Input';

import { ModalSendMessage, Container } from './styles';
import api from '../../../../services/api';

interface SignInFormData {
  name: string;
  email: string;
  restaurantName: string;
  phone: string;
}

const ModalForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('insira seu nome'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          restaurantName: Yup.string().required('insira o nome do seu negócio'),
          phone: Yup.string().required('insira seu numero'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/leads', data);
        addToast({
          title: 'Mensagem enviada',
          description: 'Entraremos em contato',
          type: 'success',
          hasdescription: true,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        // disparar toast
        addToast({
          title: 'Verifique se preencheu todos os campos',
          type: 'info',
          hasdescription: false,
        });
      }
    },
    [addToast],
  );

  const [phone, setPhone] = useState<string>(() => {
    return formRef.current?.getFieldValue('phone');
  });

  const formatPhone = useCallback((value: string) => {
    const formattedString = value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');

    setPhone(formattedString);
  }, []);

  return (
    <Container>
      <ModalSendMessage>
        <h4>ADQUIRA JÁ O SEU APP</h4>
        <h3>Entre em contato</h3>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input placeholder="Nome" name="name" type="text" />
          <Input placeholder="E-mail" name="email" type="email" />
          <Input
            placeholder="Nome do negócio"
            name="restaurantName"
            type="text"
          />
          <Input
            placeholder="Celular"
            name="phone"
            type="text"
            value={phone}
            onChange={(e) => {
              formatPhone(e.target.value);
            }}
          />
          <Button type="submit">Enviar</Button>
        </Form>
      </ModalSendMessage>
    </Container>
  );
};

export default ModalForm;
