import React from 'react';
import { FiCheck } from 'react-icons/fi';

import { Container } from './styles';

interface CardAppProps {
  title: string;
}

const CardApp: React.FC<CardAppProps> = ({ title }) => {
  return (
    <Container>
      <FiCheck size={25} />
      <p>{title}</p>
    </Container>
  );
};

export default CardApp;
