import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFielled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #fff;
  border-radius: 3px;
  padding: 10px 10px;
  width: 100%;

  border: 0.8px solid #ccc;
  color: #666360;

  & + div {
    margin-top: 8px;
  }
  display: flex;
  align-items: center;

  ${(props) =>
    props.isFocused &&
    css`
      border-color: #f58a07;
      color: #f58a07;
    `}

  ${(props) =>
    props.isFielled &&
    css`
      color: #f58a07;
    `};

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `};

  input {
    background: transparent;
    width: calc(100% - 30px);
    border: none;
    color: #111;
    font-size: 14px;

    &::placeholder {
      color: #666360;
    }
  }
  svg {
    margin-right: 3px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 0px;
  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
