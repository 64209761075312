import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface ContainerProps {
  isBlank: boolean;
}

export const Container = styled.button<ContainerProps>`
  background: #f58a07;
  height: 2rem;
  border-radius: 3px;
  border: none;
  padding: 0 16px;
  width: 100%;
  color: #fff;
  font-weight: 500;
  margin-top: 5px;
  transition: background-color 0.2s;
  font-size: 14px;
  &:hover {
    color: #fff;
    background: ${shade(0.2, '#f58a07')};
  }

  ${(props) =>
    props.isBlank &&
    css`
      background: #fff;
      color: #000;
      border: 1px solid #f58a07;
      &:hover {
        color: #fff;
        background: #f58a07;
      }
    `}
`;
