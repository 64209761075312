import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
`;

//* **********************************************   HERO */

export const Hero = styled.section`
  padding: 130px 15% 130px 15%;
  background: linear-gradient(
    270deg,
    rgba(29, 62, 139, 0.7) 0%,
    rgba(29, 62, 139, 1) 100%
  );

  @media screen and (max-width: 1150px) {
    padding: 100px 5% 50px 5%;
  }
`;

export const ContentHero = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 850px) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }
`;

export const TextHero = styled.div`
  height: 400px;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 40px 0 80px 0;
  p {
    text-align: start;
    font-size: 20px;
    color: #fff;
    padding-bottom: 40px;
    width: 80%;
  }
  @media screen and (max-width: 850px) {
    text-align: center;
    justify-self: center;
    width: 90%;
    height: 400px;
    padding: 20px 0 80px 0;
    p {
      text-align: center;
      margin: 0 auto;
      padding: 50px 0;
      width: 100%;
    }
    button {
      margin: 0 auto;
      width: 80%;
    }
  }
`;

export const Title = styled.div`
  text-align: start;
  justify-self: end;
  margin-top: 40px;
  margin-bottom: 20px;
  h3 {
    font-size: 30px;
    color: #fff;
    strong {
      color: #f58a07;
    }
  }
  @media screen and (min-width: 1800px) {
    margin-right: 50px;
  }
  @media screen and (max-width: 850px) {
    margin: 40px auto 0px auto;
    text-align: center;
    height: 100px;
  }
`;
export const ImacMockup = styled.div`
  width: 60%;
  .mocs-note {
    display: block;
    img {
      width: 100%;
    }
  }
  .mocksups {
    display: none;
    text-align: center;

    img {
      width: 60%;
    }
  }

  @media screen and (max-width: 750px) {
    width: 100%;
  }

  @media screen and (max-width: 850px) {
    .mocs-note {
      display: none;
    }
    .mocksups {
      display: block;
    }
  }
`;

export const Button = styled.button`
  padding: 10px 50px;
  background: #f58a07;
  border: 0;
  color: #fff;
  border-radius: 3px;
  font-size: 16px;
  @media screen and (max-width: 800px) {
    padding: 10px 0px;
  }
`;

//* **********************************************   WHY DELIVERY */

export const WhyDelivery = styled.section`
  padding: 100px 15% 50px 15%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1150px) {
    padding: 100px 5% 50px 5%;
  }
`;

export const TitleWhy = styled.div`
  width: 60%;
  h4 {
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    padding-bottom: 10px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #565656;
    margin-bottom: 0;
    text-align: center;
  }
  div {
    margin: 20px auto 0px auto;
    height: 2px;
    width: 10%;
    background: rgba(29, 62, 139, 1);
  }
  @media screen and (max-width: 650px) {
    width: 100%;
  }
`;

export const GridCard = styled.div`
  padding-top: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
  grid-row-gap: 0;
  @media screen and (max-width: 850px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

//* ********************************************************* WHY DASHBOARD */

export const WhyDashboard = styled.section`
  padding: 100px 15% 50px 15%;
  background: #f6f9fe;
  display: flex;
  flex-direction: row;
  width: 100%;
  div {
    padding-left: 5px;
    padding-right: 10px;
    align-self: center;
    position: relative;
    width: 50%;
    perspective-origin: left center;
    perspective: 1500px;
    transform-style: preserve-3d;
    img {
      width: 100%;
      border-radius: 10px;
      transform: rotateY(-35deg) rotateX(15deg);
      backface-visibility: hidden;
      box-shadow: 25px 60px 125px -25px rgba(80, 102, 144, 0.1),
        16px 40px 75px -40px rgba(0, 0, 0, 0.2);
    }
  }
  @media screen and (max-width: 1150px) {
    padding: 100px 5% 50px 5%;
  }
  @media screen and (max-width: 850px) {
    background: #f6f9fe;
    display: flex;
    flex-direction: column;
    div {
      width: 90%;
    }
    img {
      margin: 0 auto;
      width: 80%;
    }
  }
  @media screen and (max-width: 650px) {
    div {
      img {
        margin-top: 10px;
      }
    }
  }
`;

export const TextDashboard = styled.div`
  h4 {
    text-align: left;
    font-size: 30px;
    font-weight: 500;
    padding-bottom: 10px;
  }
  div {
    width: 100%;
  }
  p {
    padding: 0 10px;
  }
  @media screen and (max-width: 650px) {
    h4 {
      text-align: center;
    }
  }
`;

//* ************************************************ WHY APP */

export const WhyApp = styled.section`
  padding: 100px 15% 50px 15%;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  div {
    text-align: center;
    @media (max-width: 1950px) {
      img {
        width: 60%;
      }
    }
    @media (max-width: 1370px) {
      img {
        width: 60%;
      }
    }
  }
  @media screen and (max-width: 1150px) {
    padding: 100px 5% 50px 5%;
  }
  @media screen and (max-width: 850px) {
    background: #f6f9fe;
    display: flex;
    flex-direction: column-reverse;
    div {
      img {
        width: 60%;
      }
    }
  }
`;

export const TextApp = styled.div`
  width: 50%;
  margin: 0;
  h4 {
    text-align: left;
    font-size: 30px;
    font-weight: 500;
    padding-bottom: 10px;
  }
  p {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #565656;
    margin-bottom: 0;
  }
  @media screen and (max-width: 850px) {
    margin: 0 auto;
    width: 100%;

    h4 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
`;

//* ***************************************************** HOW WE WORK */

export const HowWeWork = styled.section`
  padding: 100px 10% 50px 10%;
  background: linear-gradient(
    270deg,
    rgba(29, 62, 139, 0.7) 0%,
    rgba(29, 62, 139, 1) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  .HowWeWork-row {
    display: flex;
    flex-direction: row;
  }
  @media screen and (max-width: 1150px) {
    padding: 100px 5% 50px 5%;
  }
  @media screen and (max-width: 850px) {
    .HowWeWork-row {
      flex-direction: column;
    }
  }
`;

export const TextWork = styled.div`
  width: 50%;

  h4 {
    text-align: center;
    color: #fff;
    font-size: 30px;
    font-weight: 500;
    padding-bottom: 20px;
  }
  p {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #fff;
    opacity: 0.5;
  }
  @media screen and (max-width: 850px) {
    width: 80%;
  }
`;

//* ************************************************** CUSTOMIZE */

export const Customize = styled.section`
  padding: 100px 15% 50px 15%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media screen and (max-width: 1150px) {
    padding: 100px 5% 50px 5%;
  }
`;

export const TextCustomize = styled.div`
  width: 50%;
  margin-bottom: 50px;
  h4 {
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    padding-bottom: 10px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #565656;
    margin-bottom: 0;
    text-align: center;
  }
  @media screen and (max-width: 850px) {
    width: 90%;
  }
`;

export const Footer = styled.section`
  background: linear-gradient(
    270deg,
    rgba(29, 62, 139, 0.7) 0%,
    rgba(29, 62, 139, 1) 100%
  );
  h1 {
    text-align: center;
    font-size: 16px;
    font-weight: 800;
    color: #fff;
    padding: 20px;
  }
`;

const appear = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 0.7;
  }
`;

export const OpacityContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.7;
  z-index: 100;
  animation: ${appear} 1s;
`;
