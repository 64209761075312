import React from 'react';

import { Container } from './styles';

interface CardWorkProps {
  title: string;
  description: string;
}

const CardWork: React.FC<CardWorkProps> = ({
  children,
  title,
  description,
}) => {
  return (
    <Container>
      {children}
      <h4>{title}</h4>
      <p>{description}</p>
    </Container>
  );
};

export default CardWork;
