import React, { useEffect, useState } from 'react';
import {
  FaComment,
  FaCogs,
  FaRocket,
  FaChartLine,
  FaNetworkWired,
  FaUsers,
} from 'react-icons/fa';
import App from '../../assets/app.png';
import Dashboard from '../../assets/dash.png';
import Mock from '../../assets/landing.png';
import MockUps from '../../assets/mockups.png';
import CardApp from './Components/CardApp';
import CardDashboard from './Components/CardDashboard';
import CardWork from './Components/CardWork';
import ExampleOne from './Components/ExampleOne';
import ExampleThree from './Components/ExampleThree';
import ExampleTwo from './Components/ExampleTwo';
import Header from './Components/Header';
import {
  Button,
  Container,
  ContentHero,
  Customize,
  Footer,
  Hero,
  HowWeWork,
  ImacMockup,

  // Mocs,
  TextApp,
  TextCustomize,
  TextDashboard,
  TextHero,
  TextWork,
  Title,
  WhyApp,
  WhyDashboard,
  OpacityContainer,
} from './styles';
import ModalForm from './Components/ModalForm';

const Land: React.FC = () => {
  const [timer, setTimer] = useState(1);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setTimer((t) => (t > 2 ? t - 2 : t + 1));
    }, 5000);
  }, []);

  return (
    <Container>
      <Header />

      <Hero>
        <ContentHero>
          <TextHero>
            <Title>
              <h3>
                <strong>SEJA DONO </strong>
                DO SEU DELIVERY. TENHA O SEU
                <strong> PRÓPIO APP</strong>
              </h3>
            </Title>
            <p>Faça suas vendas com comissão ZERO.</p>
            <Button
              type="button"
              onClick={() => {
                setShowModal(true);
              }}
            >
              Quero saber mais
            </Button>
          </TextHero>
          <ImacMockup>
            <div className="mocs-note">
              <img src={Mock} alt="moc" />
            </div>
            <div className="mocksups">
              <img src={MockUps} alt="MockUps" />
            </div>
          </ImacMockup>
        </ContentHero>
      </Hero>
      <Customize>
        <TextCustomize>
          <h4>O app é seu, você dá a cara que quiser.</h4>
          <p>
            Através do gerenciamento Spots, você personaliza seu cardápio, cores
            e logo com poucos cliques.
          </p>
        </TextCustomize>
        <ExampleOne timer={timer} />
        <ExampleTwo timer={timer} />
        <ExampleThree timer={timer} />
      </Customize>
      <WhyDashboard>
        <TextDashboard>
          <h4>
            Gerencie seu delivery através de um painel completo e intuitivo.
          </h4>
          <CardDashboard title="Fidelize seu cliente e se conecte ao seu público. Disponha de ferramentas poderosas como Notificações Push ilimitadas, criação de cupons  de desconto completamente customizáveis, uma base de dados de todos os clientes que já utilizaram seu app e muito mais.">
            <FaUsers size={25} />
          </CardDashboard>
          <CardDashboard title="Tenha em mãos informações relacionadas ao seu delivery em qualquer período de tempo: número de pedidos total e por dia da semana, faturamento bruto total e por mês, ticket médio, número de delivery/retiradas no balcão/cancelamentos e muito mais.">
            <FaChartLine size={25} />
          </CardDashboard>
          <CardDashboard title="Vendas por WhatsApp, telefone e Marketplaces? Centralize tudo e tenha mais controle sobre o seu fluxo de caixa. Além do mais, com seu app  próprio você não paga taxa alguma.">
            <FaNetworkWired size={25} />
          </CardDashboard>
        </TextDashboard>
        <div>
          <img src={Dashboard} alt="Dashboard" />
        </div>
      </WhyDashboard>
      <WhyApp>
        <div>
          <img src={App} alt="app" />
        </div>
        <TextApp>
          <h4>Principais vantagens do sistema Spots</h4>

          <CardApp title="Aplicativo multiplataforma iOS e Android com um design moderno e integrado em tempo real ao seu painel de gerenciamento. " />
          <CardApp title="Sem comissão por vendas. Além de aumentar sua margem de lucro, você tem mais autonomia na precificação dos itens do seu cardápio, trazendo mais vantagens para o seu cliente." />
          <CardApp title="Mantenha seu cliente atualizado sobre o andamento de seu pedido através de notificações geradas automaticamente." />
          <CardApp
            title="Faça seu negócio se sobressair em relação a sua concorrência adaptando-se à tendência tecnológica mundial.
"
          />
        </TextApp>
      </WhyApp>
      <HowWeWork>
        <TextWork>
          <h4>Como obter seu próprio app?</h4>
          <p>
            Seguindo os três passos abaixo, seu app estará nas lojas em questão
            de dias.
          </p>
        </TextWork>
        <div className="HowWeWork-row">
          <CardWork
            title="Contato"
            description="Nos contate. Assim, poderemos analisar seu pedido e garantir seu app e acesso à plataforma de gerenciamento."
          >
            <FaComment size={50} />
          </CardWork>

          <CardWork
            title="Treinamento"
            description="Receba um treinamento personalizado ao seu negócio em como utilizar a plataforma de forma eficiente, além de obter acesso à vídeos tutoriais exclusivos."
          >
            <FaCogs size={50} />
          </CardWork>

          <CardWork
            title="Aproveite"
            description="Agora você é dono do seu próprio delivery. Isso significa utilizar seu app para executar as estratégias que melhor se encaixam com seu negócio."
          >
            <FaRocket size={50} />
          </CardWork>
        </div>
      </HowWeWork>

      <Footer>
        <h1>© 2020 SPOTS SOLUÇÕES EM TECNOLOGIA</h1>
      </Footer>

      {showModal ? (
        <>
          <ModalForm />
          <OpacityContainer
            onClick={() => {
              setShowModal(false);
            }}
          />
        </>
      ) : null}
    </Container>
  );
};

export default Land;
