import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 20px 0;

  div {
    width: 50px;
    height: 50px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-right: 10px;
    svg {
      color: rgba(29, 62, 139, 1);
      margin: 20px;
    }
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #565656;
    margin-bottom: 0;
  }
  @media screen and (max-width: 650px) {
    flex-direction: column;
    p {
      margin-top: 10px;
      text-align: center;
    }
  }
`;
