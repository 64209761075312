import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import Land from '../pages/Land';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Land} />
    <Route
      path="/manalanches"
      component={() => {
        window.location.href = 'http://onelink.to/gs586b';
        return null;
      }}
    />
    <Route
      path="/fabuloso"
      component={() => {
        window.location.href = 'http://onelink.to/3qjrwj';
        return null;
      }}
    />
  </Switch>
);

export default Routes;
